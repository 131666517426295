// eslint-disable-next-line no-redeclare
/* global window, document */
import { h } from './component/element';
import DataProxy from './core/data_proxy';
import Sheet from './component/sheet';
import Bottombar from './component/bottombar';
import { cssPrefix } from './config';
import { locale } from './locale/locale';
import './index.less';
import { xtoast } from './component/message';
import Print from './component/print';

class Spreadsheet {
  constructor(selectors, options = {}) {
    let targetEl = selectors;
    this.options = options;
    this.sheetIndex = 1;
    this.activeSheetIndex = 0;
    this.datas = [];
    if (typeof selectors === 'string') {
      targetEl = document.querySelector(selectors);
    }
    this.bottombar = new Bottombar(() => {
      const d = this.addSheet();
      this.sheet.resetData(d);
    }, (index) => {
      const d = this.datas[index];
      this.activeSheetIndex = index;
      this.sheet.resetData(d);
    }, () => {
      this.deleteSheet();
    }, (index, value) => {
      this.datas[index].name = value;
    });
    this.data = this.addSheet();
    
    const rootEl = h('div', `${cssPrefix}`)
      .on('contextmenu', evt => evt.preventDefault());
    // create canvas element
    targetEl.appendChild(rootEl.el);
    this.sheet = new Sheet(rootEl, this.data,this.options.printMod,this.options.sourceData,this.options.printCb);
    if (this.options.showBottombar) {
      rootEl.child(this.bottombar.el);
    }
    this.rootEl=rootEl
  }

  addSheet(name, active = true) {
    const n = name || `sheet${this.sheetIndex}`;
    const d = new DataProxy(n, {...this.options,rootEl:this.rootEl});
    d.change = (...args) => {
      this.sheet.trigger('change', ...args);
    };
    this.datas.push(d);
    // console.log('d:', n, d, this.datas);
    if (active) {
      this.activeSheetIndex = this.datas.length - 1;
    }
    this.bottombar.addItem(n, active);
    this.sheetIndex += 1;
    return d;
  }

  deleteSheet() {
    const [oldIndex, nindex] = this.bottombar.deleteItem();
    if (oldIndex >= 0) {
      this.datas.splice(oldIndex, 1);
      if (nindex >= 0) this.sheet.resetData(this.datas[nindex]);
    }
  }
  saveData(){//保存数据
    console.log(this)
  }
  loadData(data) {
    
    if(Array.isArray(data)){
      !data.length?data.push({}):''
    }else{
      data=[data]
    }
    const ds = data;
    this.bottombar.clear();
    this.datas = [];
    if (ds.length > 0) {
      this.datas = [];
      for (let i = 0; i < ds.length; i += 1) {
        const it = ds[i];
        const nd = this.addSheet(it.name, i === 0);
        nd.setData(it);
        if (i === 0) {
          this.sheet.resetData(nd);
        }
      }
    }
    return this;
  }

  getData() {
    return this.datas.map(it => it.getData());
  }

  cellText(ri, ci, text, sheetIndex = 0) {
    this.datas[sheetIndex].setCellText(ri, ci, text, 'finished');
    return this;
  }

  cellField(ri, ci, field, sheetIndex = 0) {
    let has=this.datas[sheetIndex].fieldList.findIndex((it)=>it==field.uuid)
    if(has>-1){
      this.sheet.trigger('error', '不允许重复配置相同字段');
      return this;
    }
    this.datas[sheetIndex].setCellField(ri, ci, field, 'finished');
    this.datas[sheetIndex].fieldList.push(field.uuid)
    return this;
  }
  printData(sheetIndex=0){
    let data=this.datas[sheetIndex]
    data.rootEl.removeChild(this.sheet.print.el.el)
    this.sheet.print = new Print(data,this.options.sourceData,this.options.printCb);
    data.rootEl.children(this.sheet.print.el)
    this.sheet.print.preview();
  }
  reSetSize(){
    const { data } = this;
    this.sheet.reload()
  }
  cell(ri, ci, sheetIndex = 0) {
    return this.datas[sheetIndex].getCell(ri, ci);
  }

  cellStyle(ri, ci, sheetIndex = 0) {
    return this.datas[sheetIndex].getCellStyle(ri, ci);
  }

  reRender() {
    this.sheet.table.render();
    return this;
  }

  on(eventName, func) {
    this.sheet.on(eventName, func);
    return this;
  }

  validate() {
    const { validations } = this.data;
    return validations.errors.size <= 0;
  }

  change(cb) {
    this.sheet.on('change', cb);
    return this;
  }

  static locale(lang, message) {
    locale(lang, message);
  }
}

const spreadsheet = (el, options = {}) => new Spreadsheet(el, options);

if (window) {
  window.x_spreadsheet = spreadsheet;
  window.x_spreadsheet.locale = (lang, message) => locale(lang, message);
}

export default Spreadsheet;
export {
  spreadsheet,
};
