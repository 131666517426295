function getDPI() {
  const arrDPI = [];
  if (window.screen.deviceXDPI != undefined) { // ie 9
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else { // chrome firefox
    const tmpNode = document.createElement('div');
    tmpNode.style.cssText = 'width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden';
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arrDPI;
}
export const PAGER_SIZES = [
  ['A3', 11.69, 16.54],
  ['A4', 8.27, 11.69],
  ['A5', 5.83, 8.27],
  ['B4', 9.84, 13.90],
  ['B5', 6.93, 9.84],
];
export const PAGER_ORIENTATIONS = ['landscape', 'portrait'];
export function inches2px(inc) {
  return parseInt(getDPI()[1] * inc, 10);
}
