import Dropdown from './dropdown';
import { h } from './element';
import { cssPrefix } from '../config';
import { PAGER_SIZES,PAGER_ORIENTATIONS } from './printUtils';
import { t } from '../locale/locale';
export default class DropdownPrintArea extends Dropdown {
  constructor(area,dir) {
    const headerStr=h('div', `${cssPrefix}-item-header`).child('纸张尺寸');
    const nPrintArea= h('select', 'direction-select area-select').children(
      ...PAGER_SIZES.map((it, index) => h('option', 'direction-item').attr('value', index).child(`${it[0]}：${it[1]} x ${it[2]}`)),
    ).on('change', (e)=>{
      this.area=e.target.value
    }).val(area);
    const footerStr=h('div', `${cssPrefix}-item-header`).child('打印方向')
    const directionStr = h('select', 'direction-select dir-select').children(
      ...PAGER_ORIENTATIONS.map((it, index) => h('option', 'direction-item').attr('value', it).child(`${t('print.orientations')[index]}`)),
    ).on('change', (e)=>{
      this.direction=e.target.value;
      console.log(this.direction)
    }).val(dir);
    const operatStr=h('div', `${cssPrefix}-item-header`).children(
      h('button','operat-btn cancel').html('取消').on('click',()=>{
        this.hide();
      }),h('button','operat-btn sure').html('确认').on('click', () => {
        this.setTitle(this.area);
        this.change({area:this.area,direction:this.direction});
      })
    )
    let htmlArr=[headerStr,nPrintArea,footerStr,directionStr,operatStr]
    super(PAGER_SIZES[area][0], '150px', true, 'bottom-left', ...htmlArr);
    this.area=area;
    this.direction=dir;
    
  }
  setTitle(area) {
    this.title.html(PAGER_SIZES[area][0]);
    this.hide();
  }
  
}
