import DropdownItem from './dropdown_item';
import DropdownPrintArea from '../dropdown_printarea';

export default class PrintArea extends DropdownItem {
  constructor(area,dir) {
    super('print-area','',{area,dir});
    this.area=area;
    this.direction=dir
  }

  getValue(it) {
    return it;
  }

  dropdown() {
    console.log(this)
    return new DropdownPrintArea(this.value.area,this.value.dir);
  }
  setState(v) {
    if (v) {
      this.value = v;
      this.dd.setTitle(v.area);
      this.dd.el.querySelector('.area-select').value=v.area
      this.dd.el.querySelector('.dir-select').value=v.dir
    }
}
}
