// eslint-disable-next-line no-redeclare
/* global window document */
import { h } from './element';
import { cssPrefix } from '../config';
import Button from './button';
import { Draw, npx } from '../canvas/draw';
import { renderPrintCell, cellPaddingWidth } from './table';
import { t } from '../locale/locale';
import { PAGER_SIZES, PAGER_ORIENTATIONS, inches2px } from './printUtils';
import helper from '../core/helper';
import { getFontSizePxByPt } from '../core/font';
import { xtoast } from './message';

// resolution: 72 => 595 x 842
// 150 => 1240 x 1754
// 200 => 1654 x 2339
// 300 => 2479 x 3508
// 96 * cm / 2.54 , 96 * cm / 2.54
let oldData;
function btnClick(type='cancel') {
  const { data } = this;
  data.rows.setData(oldData)
  oldData = null;
  if (type === 'cancel') {
    this.el.hide();
  } else {
    this.toPrint();
  }  
  if(data.settings.printMod){
    data.rootEl.el.parentElement.innerHTML=''
  }
  data.settings.closePrintCb&&data.settings.closePrintCb()
}

function pagerSizeChange(evt) {
  const { paper,data } = this;
  const { value } = evt.target;
  const ps = PAGER_SIZES[value];
  paper.w = inches2px(ps[1]);
  paper.h = inches2px(ps[2]);
  // console.log('paper:', ps, paper);
  data.rows.setData(oldData)
  oldData = null;
  this.preview();
}
function pagerOrientationChange(evt) {
  const { paper,data } = this;
  const { value } = evt.target;
  const v = PAGER_ORIENTATIONS[value];
  paper.orientation = v;
  data.rows.setData(oldData)
  oldData = null;
  this.preview();
}

export default class Print {
  constructor(data,sourceData) {
    this.paper = {
      w: inches2px(PAGER_SIZES[data.printArea][1]),
      h: inches2px(PAGER_SIZES[data.printArea][2]),
      padding: 20,
      orientation: data.printDirection,
      get width() {
        return this.orientation === 'landscape' ? this.h : this.w;
      },
      get height() {
        return this.orientation === 'landscape' ? this.w : this.h;
      },
    };
    this.sourceData=sourceData;
    this.data = data;
    this.el = h('div', `${cssPrefix}-print`)
      .children(
        h('div', `${cssPrefix}-print-bar`)
          .children(
            h('div', '-title').child('打印预览'),
            h('div', '-right').children(
              h('div', `${cssPrefix}-buttons`).children(
                new Button('cancel').on('click', btnClick.bind(this, 'cancel')),
                new Button('next', 'primary').on('click', btnClick.bind(this, 'next')),
              ),
            ),
          ),
        h('div', `${cssPrefix}-print-content`)
          .children(
            this.contentEl = h('div', '-content'),
            h('div', '-sider').child(
              h('form', '').children(
                h('fieldset', '').children(
                  h('label', '').child(`${t('print.size')}`),
                  h('select', '').children(
                    ...PAGER_SIZES.map((it, index) => h('option', '').attr('value', index).child(`${it[0]} ( ${it[1]}''x${it[2]}'' )`)),
                  ).on('change', pagerSizeChange.bind(this)).val(this.data.printArea),
                ),
                h('fieldset', '').children(
                  h('label', '').child(`${t('print.orientation')}`),
                  h('select', '').children(
                    ...PAGER_ORIENTATIONS.map((it, index) => h('option', '').attr('value', it).child(`${t('print.orientations')[index]}`)),
                  ).on('change', pagerOrientationChange.bind(this)).val(this.data.printDirection),
                ),
              ),
            ),
          ),
      ).hide();
  }

  resetData(data) {
    this.data = data;
  }

  preview() {
    const { data, paper } = this;
    const { width, height, padding } = paper;
    const iwidth = width;
    const iheight = height;
    console.time('print');
    const {printData,hasSetHeader,offList}=this.dealData();
    if(!printData.length){
      data.settings.printCallBack&&data.settings.printCallBack('模板为空，无法打印')
      data.settings.closePrintCb&&data.settings.closePrintCb()
      return;
    }
    const cr = data.contentRange();
    const scale = iwidth / cr.w;
    let left = padding;
    let {sci,eci,sri,eri} = data.printTableHeader.range||{};
    if (scale > 1) {
      left += (iwidth - cr.w) / 2;
    }
    let rIndex = 0;
    this.contentEl.html('');
    this.canvases = [];
    const mViewRange = {
      sri: 0,
      sci: 0,
      eri: 0,
      eci: 0,
    };
    
    (async function w(context) {
      for (let i = 0; i < printData.length; i += 1) {
        let yoffset = -i*height;
        let yt=0;
        let curYo=offList[i];//当前页偏移修复值
        let curPageInfo=printData[i];
        const curPageRows=Object.keys(curPageInfo);
        const wrap = h('div', `${cssPrefix}-canvas-card`).attr('style',`width:${width}px`);
        let canvas = h('canvas', `${cssPrefix}-canvas`);
        context.canvases.push(canvas.el);
        const draw = new Draw(canvas.el, width, height, false);
        // cell-content
        if(hasSetHeader&&i){
          await (async function() {
            const fullH=data.rows.sumHeight(0,sri)
            for (let tri=sri; tri <= eri; tri += 1) {
              const rh = data.rows.getHeight(tri);
              await (async function (RI) {
                
                
                for (let tci = sci; tci <= eci; tci += 1) {
                  draw.save();
                  if (scale < 1) {
                    draw.translate(left * scale, 0);
                  } else {
                    draw.translate(left, 0);
                  }
                  let cell=data.getCell(RI, tci)
                  if(cell&&cell.merge){
                    for (let dri = RI; dri <= RI+cell.merge[0]; dri++) {
                      for (let dci = tci; dci <= tci+cell.merge[1]; dci++) {
                        if(!(dri===RI&&tci===dci)){
                          data.rows.setCell(dri, dci,null)
                        }
                      }
                    }
                  }
                  await renderPrintCell(draw, data, RI, tci, -fullH);
                 
                  draw.restore();
                }
                
                yt+=rh
              }(tri));
              
            }
          }());
          console.log('yoffsetTable',yt)
        }
        let offNum=yt*i+curYo;
        await (async function() {
          for (let j=0; j < curPageRows.length; j ++) {
            let ri=curPageRows[j]*1;
        
            await (async function (RI) {
              for (let ci = 0; ci < data.cols.len; ci ++) {
                draw.save();
                if (scale < 1) {
                  draw.translate(left * scale, 0);
                } else {
                  draw.translate(left, 0);
                }
                await renderPrintCell(draw, data, RI, ci, yoffset+offNum);
                mViewRange.eci = ci;
                draw.restore();
              }
            }(ri));
            rIndex=ri
          }
          
          
        }());
        mViewRange.eri = rIndex;
        draw.restore();
        
        // const yof = yoffset;
        data.asyncEachMergesInView(mViewRange,  async ({ sri, sci }) => {
          draw.save();
          if (scale < 1) {
            draw.translate(left * scale, 0);
          } else {
            draw.translate(left, 0);
          }
          await renderPrintCell(draw, data, sri, sci, i?(yoffset+offNum):0);
          draw.restore();

        });
        
        mViewRange.sri = mViewRange.eri;
        mViewRange.sci = 0;
        context.contentEl.child(h('div', `${cssPrefix}-canvas-card-wraper`).child(wrap.child(canvas)));
        context.el.show();
        console.timeEnd('print');
      }
      // context.el.show(); 
      
    }(this));
    
  }
  acuHeight(rh,baseH){
    let m=rh%baseH;
    let middleH=baseH/2;
    let res=rh;
    if(m<=middleH){
      res=rh-m
    }else{
      res=rh+(baseH-m)
    }
    return res
  }
  dealData() {
    const { data, paper ,sourceData } = this;
    const {height} = paper;
    let errorText='';
    let yoo=0;
    let offList=[0];
    const rowInstance = data.rows;
    const colInstance = data.cols;
    // eslint-disable-next-line no-unused-expressions
    oldData = helper.cloneDeep(data.rows.getData());// 保存原始数据
    const colsLen = colInstance.len;
    const canvas = h('canvas', `${cssPrefix}-canvas-hide`);
    const draw = new Draw(canvas.el);
    draw.save();
    const hasSetHeader=!!data.printTableHeader.range;
    let {sci,eci,sri,eri} = data.printTableHeader.range||{}
    const tableHeaderRows=hasSetHeader?eri-sri+1:0;
    const tableHeaderHeight=hasSetHeader?this.dealPrintLineHeight(sci,eci,sri,eri):0;
    if(tableHeaderHeight>=height){
      data.settings.printCallBack&&data.settings.printCallBack('打印失败，表头高度必须小于一页')
      data.settings.closePrintCb&&data.settings.closePrintCb()
      return;
    }
    let printData=[];
    let prePageEndRi=-1;
    let curPageNum=0;
    for (let ri = 0; ri < rowInstance.len; ri++) {
      let row = rowInstance._[ri];
      row?row.height=this.acuHeight(rowInstance.getHeight(ri)||rowInstance.height,rowInstance.height):undefined;
      const curSize=rowInstance.sumHeight(prePageEndRi+1,ri);//当前页已经被渲染的区域高度大小
      let iHeight=height-tableHeaderHeight*Boolean(curPageNum);
      const remanentSize=iHeight-curSize;//当前页面剩余的渲染空间
      //获取当前行拆分子单元格的最大值
      for (let cci = 0; cci < colsLen; cci++){
        const cell2 = rowInstance.getCell(ri, cci);
        let cellValue2 = [];
        if (cell2 && cell2.uuid&&!cell2.insert) {
          cellValue2 = sourceData[cell2.uuid];
          cell2.fieldName=cell2.fieldName||cell2.text;
          cell2.text = cellValue2;
        }
        if (cell2 && cell2.type == 3 && Array.isArray(cellValue2)&&!cell2.insert) { 
          row.len=(!row.len||row.len<cellValue2.length)?cellValue2.length:row.len//设置每一行中拆分的最多行值，根据单元格值判断
        }
      }
      let rowSizeList=[]
      let totalRowNum=Math.floor(remanentSize/rowInstance.height);
      let maxHeight=totalRowNum<=tableHeaderRows?1:totalRowNum-tableHeaderRows;
      //计算行高

      for (let ci = 0; ci < colsLen; ci++){
        
        const cell = rowInstance.getCell(ri, ci);
        
        let isMultiLine=0;//判断是否是多行文本
        const bw = npx(colInstance.getWidth(ci) - (cellPaddingWidth * 2) - 2);
        let cellValue = [];
        if (cell && cell.uuid&&!cell.insert) {
          cellValue = sourceData[cell.uuid];
          cell.text = cellValue;
        }
        if (cell && cell.type == 3 && Array.isArray(cellValue)&&!cell.insert) { // 根据单元格内容长度自动换行 增加单元格高度
          let addHeight = 0;         
          cell.lineSize = [];
          const style = data.getCellStyleOrDefault(ri, ci);
          const font = Object.assign({}, style.font);
          font.size = getFontSizePxByPt(font.size);
          draw.attr({
            font: `${font.italic ? 'italic' : ''} ${font.bold ? 'bold' : ''} ${npx(font.size)}px ${font.name}`,
          });
          isMultiLine=0;
          Array(rowInstance._[ri].len).fill(0).forEach((item,_ind) => {
            const ntxts = [];
            let it=cellValue[_ind]||''
            let itList=it.split('\n')//多行文本
            itList.length>1&&isMultiLine++
            let ah2=itList.length==1?0:itList.length;
            itList.forEach(subIt=>{
              const tw1 = draw.ctx.measureText(subIt).width;
              if (tw1 > bw) {
                let textLine = { w: 0, len: 0, start: 0 };
                for (let i = 0; i < subIt.length; i += 1) {
                  if (textLine.w >= bw) {
                    ah2 += 1;
                    ntxts.push(subIt.substr(textLine.start, textLine.len));
                    textLine = { w: 0, len: 0, start: i };
                  }
                  textLine.len += 1;
                  textLine.w += draw.ctx.measureText(subIt[i]).width + 1;
                }
                if (textLine.len > 0) {
                  ah2 += 1;
                  ntxts.push(subIt.substr(textLine.start, textLine.len));
                }
              } else {
                ah2 += 1;
                ntxts.push(subIt);
              }
            })
            addHeight+=ah2
            
            if(ntxts.length){
              maxHeight<ntxts.length?cell.lineSize.push(maxHeight):cell.lineSize.push(ntxts.length);
            }else{
              cell.lineSize.push(0);
            }
          });
          if (row.height < addHeight * rowInstance.height || !row.height) {
            row.height = addHeight * rowInstance.height;// 根据数据量重设单元格高度 以便在单元格内拆分
            row.height=this.acuHeight(row.height,rowInstance.height)
            cell.__height=row.height
            addHeight = 0;
          }
          if(cell.__height&&remanentSize<cell.__height&&isMultiLine&&!row.insert){
            // row.height=this.acuHeight(remanentSize,rowInstance.height)
            console.log(cell)
            errorText=errorText||cell.fieldName
          }

        }

        if (cell && cell.type === 1&&!cell.insert) {
          const ntxts = [];
          let it=cellValue||'';
          let itList=it.split('\n')//多行文本
          let addLine=0;
          cell.lineSize = [];
          itList.forEach(subIt=>{
            const txtWidth = draw.ctx.measureText(subIt).width;
            if (txtWidth > bw) {
              let textLine = { w: 0, len: 0, start: 0 };
              for (let i = 0; i < subIt.length; i += 1) {
                if (textLine.w >= bw) {
                  addLine += 1;
                  ntxts.push(subIt.substr(textLine.start, textLine.len));
                  textLine = { w: 0, len: 0, start: i };
                }
                textLine.len += 1;
                textLine.w += draw.ctx.measureText(subIt[i]).width + 1;
              }
              if (textLine.len > 0) {
                addLine += 1;
                ntxts.push(subIt.substr(textLine.start, textLine.len));
              }
            } else {
              addLine += 1;
              ntxts.push(subIt);
            }
          })
          
          
          let addH=addLine * rowInstance.height
          if (row.height < addH || !row.height) {
            row.height = Math.min(addH,remanentSize);// 根据数据量重设单元格高度 以便在单元格内拆分
            row.height=this.acuHeight(row.height,rowInstance.height)
            cell.__height=row.height
          }
          if(cell.__height&&remanentSize<cell.__height&&!row.insert){
            // row.height=this.acuHeight(remanentSize,rowInstance.height)
            console.log(cell)
            errorText=errorText||cell.fieldName
          }
        }
        
        if(cell&&cell.lineSize){
          cell.lineSize.forEach((item,_ind)=>{
            rowSizeList[_ind]=(!rowSizeList[_ind]||item>rowSizeList[_ind])?item:rowSizeList[_ind]
          })
        }
        
      }
      
      const needSpace=rowInstance.getHeight(ri);//当前行渲染需要的空间高度大小
      let setLineSize=[];
      let nextLineSize=[];
      // if(row&&remanentSize<row.height){
      //   // row.height=this.acuHeight(remanentSize,rowInstance.height)
      //   errorText=errorText||`模板第${ri+1}行内容过多，打印后可能会显示不全，请调整模板中对应单元格大小`
      // }
      let diffSpace=remanentSize-needSpace
      if(diffSpace<rowInstance.height){//如果剩余空间不够渲染当前行 则分页
        row=rowInstance._[ri];
        let sum=rowSizeList.reduce((a,b)=>{
          let sum=a+b;
          if((sum*rowInstance.height)<remanentSize){
            setLineSize.push(b)
          }else{
            nextLineSize.push(b)
          }
          return a+b
        },0)

      }
      if(nextLineSize.length){
        if(setLineSize.length&&row){
          row.lineSize=setLineSize
        }
        let nextCells={};
        for (const key in row.cells) {
          const kNum=Number(key);
          let cell=row.cells[kNum];
          nextCells[kNum]=JSON.parse(JSON.stringify(cell))
          if(cell.text&&Array.isArray(cell.text)){
            let sLen=setLineSize.length;
            if(sLen){
              cell.text=cell.text.slice(0,sLen);
              cell.lineSize=cell.lineSize.slice(0,sLen);
              row.height=remanentSize;
              row.len=sLen;
            }else{
              delete cell.type
              delete cell.lineSize
              delete cell.uuid
              delete cell.text
              row.height=remanentSize
              row.len=0
            }
            cell.text=sLen?cell.text.slice(0,sLen):'';
            nextCells[kNum].text=nextCells[kNum].text.slice(sLen);
            nextCells[kNum].insert=true;
            
          }
        }
        data.printInsert(ri+1)
       
        let nexRow=rowInstance.getOrNew(ri+1);
        for (const key in nextCells) {//遍历新增的行单元格填充内容
          let nCell=nextCells[key]
          nCell.lineSize?nCell.lineSize=nCell.lineSize.slice(setLineSize.length):""
          rowInstance.setCell(ri+1,Number(key),nCell)
        }
        nexRow.height=nextLineSize.reduce((a,b)=>a+b)*25;
        nexRow.lineSize=nextLineSize;
        nexRow.len=nextLineSize.length;
        nexRow.insert=true;
      }else if(row){
        if(row.height>remanentSize){
          yoo+=(row.height-remanentSize)
          row.height=remanentSize
        }
        !row.insert&&rowSizeList.length?row.lineSize=rowSizeList:''
        if(!row.lineSize||!row.lineSize.length){
          row.lineSize=[1]
        }
      }
     
      printData[curPageNum]=printData[curPageNum]||{}
      printData[curPageNum][ri]=row;
      
      if(diffSpace<rowInstance.height){//如果剩余空间不够渲染当前行 则分页
        row&&row.lineSize.length==1&&Math.abs(diffSpace)<iHeight?yoo+=diffSpace:''
        curPageNum++;
        prePageEndRi=ri;//取当前行的前一行索引为当前页结束索引
        console.log(`第${curPageNum}页偏移：`,yoo)
        offList[curPageNum]=yoo;
      }
      
    }
    errorText&&data.settings.printCallBack&&data.settings.printCallBack(`模板字段${errorText}内容过多，打印后可能会显示不全，请调整模板中对应单元格大小`)
    draw.restore();
    let result=this.dealSpacePage(printData);//处理分页后空白单元格
    
    return {printData:result,hasSetHeader,offList}
  }
  dealSpacePage(list){
    let hasContent;
    do {  
      let lastPage=Object.values(list[list.length-1]);
      hasContent=!!(lastPage.some(it=>{
        let res=0;
        if(it){
          Object.entries(it.cells).forEach(([ci, cell]) => {
            if(cell&&(cell.text||cell.hasOwnProperty('style'))){
              res++
            }
          });
        }
        return !!res
      }))
      if(!hasContent){
        list.pop()
      }
    } while (!hasContent&&list.length>1);
    return list;
  }
  dealPrintLineHeight(sci,eci,sri,eri){
    const { data ,sourceData,paper } = this;
    const {height} = paper;
    const rowInstance =data.rows;
    const colInstance =data.cols;
    const canvas = h('canvas', `${cssPrefix}-canvas-hide`);
    const draw = new Draw(canvas.el);
    let  result=0;
    for (let ri = sri; ri <= eri; ri++) {
      let rowLen=1;
      let rowHeight=rowInstance.getHeight(ri);
      //循环获取当前行拆分子单元格的最大值
      for (let cci = sci; cci <= eci; cci++){
        const cell2 = rowInstance.getCell(ri, cci);
        let cellValue2 = [];
        if (cell2 && cell2.uuid) {
          cellValue2 = sourceData[cell2.uuid];
          cell2.text = cellValue2;
        }
        if (cell2 && cell2.type == 3 && Array.isArray(cellValue2)) { 
          rowLen=Math.max(cellValue2.length,rowLen)//设置每一行中拆分的最多行值，根据单元格值判断
        }
      }
  
      //计算行高
      let maxHeight=height/rowInstance.height/5;
      for (let ci = sci; ci <= eci; ci++){
        
        const cell = rowInstance.getCell(ri, ci);
        const bw = npx(colInstance.getWidth(ci) - (cellPaddingWidth * 2) - 2);
        let cellValue = [];
        if (cell && cell.uuid) {
          cellValue = sourceData[cell.uuid];
        }
        if (cell && cell.type == 3 && Array.isArray(cellValue)) { // 根据单元格内容长度自动换行 增加单元格高度
          let addHeight = 0;
          cell.lineSize = [];
          const style = data.getCellStyleOrDefault(ri, ci);
          const font = Object.assign({}, style.font);
          font.size = getFontSizePxByPt(font.size);
          draw.attr({
            font: `${font.italic ? 'italic' : ''} ${font.bold ? 'bold' : ''} ${npx(font.size)}px ${font.name}`,
          });
          Array(rowLen).fill(0).forEach((item,_ind) => {
            let it=cellValue[_ind]||''
            const txtWidth = draw.ctx.measureText(it).width;
            if (txtWidth > bw) {
              let textLine = { w: 0, len: 0, start: 0 };
              for (let i = 0; i < it.length; i += 1) {
                if (textLine.w >= bw) {
                  addHeight += 1;
                  textLine = { w: 0, len: 0, start: i };
                }
                textLine.len += 1;
                textLine.w += draw.ctx.measureText(it[i]).width + 1;
              }
              if (textLine.len > 0) {
                addHeight += 1;
              }
            } else {
              addHeight += 1;
            }
          });
          const addH = addHeight * rowInstance.height;// 根据数据量重设单元格高度 以便在单元格内拆分
          rowHeight =Math.max(addH,rowInstance._[ri].height||rowInstance.height,rowHeight||0) ;// 根据数据量重设单元格高度 以便在单元格内拆分
          rowHeight=this.acuHeight(rowHeight,rowInstance.height)
          addHeight = 0;
        }

        if (cell && cell.type === 1) {
          let addLine = 0;
          const txtWidth = draw.ctx.measureText(cellValue).width;
          cell.lineSize = [];
          if (txtWidth > bw) {
            let textLine = { w: 0, len: 0, start: 0 };
            for (let i = 0; i < cellValue.length; i += 1) {
              if (textLine.w >= bw) {
                addLine += 1;
                textLine = { w: 0, len: 0, start: i };
              }
              textLine.len += 1;
              textLine.w += draw.ctx.measureText(cellValue[i]).width + 1;
            }
            if (textLine.len > 0) {
              addLine += 1;
            }
          } else {
            addLine += 1;
          }
          let addH=addLine * (rowInstance.height)
          rowHeight =Math.max(addH,rowInstance._[ri].height||rowInstance.height,rowHeight) ;// 根据数据量重设单元格高度 以便在单元格内拆分
          rowHeight=this.acuHeight(rowHeight,rowInstance.height)
          addLine=0
        }
        
        
      }
      
      rowHeight=this.acuHeight(rowHeight,rowInstance.height)
      console.log(`表头${ri}行`,rowHeight)
      result+=rowHeight
      
    }
    return result
  }
  toPrint() {
    this.el.hide();
    const { paper } = this;
    const iframe = h('iframe', '').hide();
    const { el } = iframe;
    window.document.body.appendChild(el);
    const { contentWindow } = el;
    const idoc = contentWindow.document;
    const style = document.createElement('style');
    style.innerHTML = `
      @page { size: ${paper.width}px ${paper.height}px; };
      canvas {
        page-break-before: auto;        
        page-break-after: always;
        image-rendering: pixelated;
      };
    `;
    idoc.head.appendChild(style);
    this.canvases.forEach((it) => {
      const cn = it.cloneNode(false);
      const ctx = cn.getContext('2d');
      // ctx.imageSmoothingEnabled = true;
      ctx.drawImage(it, 0, 0);
      idoc.body.appendChild(cn);
    });
    contentWindow.print();
  }
}
